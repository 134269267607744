#contact {
    margin: $SECTION_SPACING_XL 0 $SECTION_SPACING_LG;
    padding: $SECTION_SPACING;
    
    & > * {
        margin: 0 auto;
        max-width: $MAX-WIDTH;
    }

    h2 {
        margin-bottom: $SECTION_SPACING_LG;
    }

    .contact-flexbox {
        display: flex;
        justify-content: space-between;
        height: auto;
        border: 1px solid $COLOR_PRIMARY;
        border-radius: .5em 0 0 .5em;
        margin: $SECTION_SPACING auto;
        position: relative;
        overflow: hidden;

        @media screen and (max-width: 750px) {
            border-radius: .5em;
        }

        &::before {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            height: 100%;
            width: 61%;
            background: #c285a4;
            z-index: 2;
            clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 7% 100%);

            @media screen and (max-width: 750px) {
                display: none;
            }
        }

        &::after {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            height: 100%;
            width: 60%;
            background: $COLOR_PRIMARY;
            z-index: 3;
            clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 7% 100%);

            @media screen and (max-width: 750px) {
                clip-path: none;
                width: 100%;
            }
        }
    }

    canvas.contact-animation {
        position: relative;
        z-index: 1;
        pointer-events: auto;

        @media screen and (max-width: 750px) {
            display: none;
        }
    }

    form.contact-form {
        width: 50%;
        display: flex;
        flex-flow: column nowrap;
        padding: $SECTION_SPACING_LG;
        z-index: 4;

        @media screen and (max-width: 750px) {
            width: 100%;
        }


        input[type="text"],
        input[type="email"],
        textarea {
            background: rgba(white, .2);
            border: none;
            font-family: inherit;
            font-size: .7em;
            padding: 1em;
            margin-bottom: 1em;
            color: $COLOR_OFF_WHITE;
            transition: outline 200ms ease-in-out;

            &:placeholder {
                color: rgba(255,255,255,0.9);
            }

            &:focus {
                outline: 2px solid rgba(white, .5);
            }
        }

        .contact-button {
            align-self: flex-end;
            padding: 1em;
            background: $COLOR_PRIMARY;
            border: 2px solid rgba(white, .2);
            color: $COLOR_OFF_WHITE;
            font-size: .7em;
            font-family: inherit;
            transform: translateX(-.7em) skewX(-25deg);
            overflow: hidden;
            position: relative;
            transition: all 200ms ease-out .2s;
            cursor: pointer;

            & span {
                display: inline-block;
                transform: skewX(25deg);
            }

            &::before {
                content: '';
                width: 100%;
                height: 100%;
                background: $COLOR_OFF_WHITE;
                position: absolute;
                top: 0;
                left: -100%;
                transition: left .8s cubic-bezier(0.86, 0, 0.07, 1);
                z-index: -1;
            }

            &::after {
                content: '';
                width: 100%;
                height: 100%;
                background: rgba($COLOR_OFF_WHITE, .3);
                position: absolute;
                top: 0;
                left: -100%;
                transition: left .6s cubic-bezier(0.86, 0, 0.07, 1) .2s;
                z-index: -2;
            }

            &:hover {
                color: $COLOR_PRIMARY;
            }

            &:hover::before {
                left: 0%;
                transition: left .6s cubic-bezier(0.86, 0, 0.07, 1) .2s;
            }
            &:hover::after {
                left: 0%;
                transition: left .8s cubic-bezier(0.86, 0, 0.07, 1);
            }
        }

        .button-success {
            background: $COLOR_SECONDARY;
        }

        .button-fail {
            background: $COLOR_TERTIARY;
        }
    }
}

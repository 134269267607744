header {
    width: 100%;
    position: absolute;
    top: 0;
    padding: $SECTION_SPACING;
    z-index: 100;
}

nav {
    display: flex;
    justify-content: flex-end;
    max-width: $MAX-WIDTH;
    margin: 0 auto;
}

header a {
    text-decoration: none;
    // background: $COLOR_OFF_BLACK;
    border: none;
    color: $COLOR_ACCENT_SECONDARY;
    font-size: 2rem;
    padding: 1rem 2rem;
    transition: background-color 200ms ease-in;
}

header a:hover {
    background: $COLOR_OFF_BLACK;
}

header a:not(:last-child) {
    margin-right: 1rem;
}
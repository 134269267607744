#footer {
    background: $COLOR_BLACK;
    color: $COLOR_OFF_WHITE;
    padding: $SECTION_SPACING;
}

#footer > * {
    margin: 0 auto;
    max-width: $MAX-WIDTH;
}

.footer-links {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(6em, 1fr));
    align-items: center;
    gap: 2em;
    padding: $SECTION_SPACING_XL 0;

    a {
        place-self: center;
    }

    svg {
        width: 4em;
        transition: all 200ms ease-out;
        cursor: pointer;
    }
    svg:not(.stroke-svg) {
        fill: $COLOR_OFF_WHITE;

        &:hover {
            fill: $COLOR_TERTIARY;
        }
    }
    svg.stroke-svg {
        stroke: $COLOR_OFF_WHITE;

        &:hover {
            stroke: $COLOR_TERTIARY;
        }
    }
}

#copyright {
    background: $COLOR_OFFER_BLACK;
    color: $COLOR_OFF_WHITE;
    padding: 1em;
    font-size: .8em;
    display: flex;
    justify-content: flex-end;
}
// reset
*, 
*::before, 
*::after {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

// font stuff
html {
    font-size: 62.5%;
    scroll-behavior: smooth;
}

body {
    font-size: $FONT_SIZE_DEFAULT;
    font-family: 'Open Sans', sans-serif;
    color: $COLOR_BLACK;
    overflow-x: hidden;
}

h2 {
    color: $COLOR_PRIMARY;
}

::selection {
    background-color: $COLOR_PRIMARY;
    color: $COLOR_OFF_WHITE;
}
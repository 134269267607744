@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Open Sans Regular"), local("OpenSans-Regular"), url("OpenSans-Regular.c602053a.ttf") format("truetype");
}
@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: local("Open Sans Italic"), local("OpenSans-italic"), url("OpenSans-italic.649c4027.ttf") format("truetype");
}
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: local("Open Sans ExtraBold"), local("OpenSans-ExtraBold"), url("OpenSans-ExtraBold.ac07d218.ttf") format("truetype");
}
@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 800;
  font-display: swap;
  src: local("Open Sans ExtraBold Italic"), local("OpenSans-ExtraBolditalic"), url("OpenSans-ExtraBolditalic.c0889767.ttf") format("truetype");
}
@font-face {
  font-family: "Homemade Apple";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local("Homemade Apple Regular"), local("HomemadeApple-Regular"), url("HomemadeApple-Regular.b1762585.ttf") format("truetype");
}
*,
*::before,
*::after {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}
html {
  font-size: 62.5%;
  scroll-behavior: smooth;
}
body {
  font-size: 3rem;
  font-family: "Open Sans", sans-serif;
  color: #121212;
  overflow-x: hidden;
}
h2 {
  color: #850D4A;
}
::selection {
  background-color: #850D4A;
  color: #F9F1F1;
}
#about {
  background: #007C89;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  line-height: 1.5;
  padding: 4rem 2rem;
  transform: skewY(-3deg);
}
#about > * {
  width: 100%;
  max-width: 120rem;
  transform: skewY(3deg);
}
#about h2 {
  color: #F9F1F1;
  margin: 4rem 0 2rem;
}
#about h3 {
  font-size: 1.4em;
  font-family: "Homemade Apple", cursive;
  color: #F9F1F1;
  text-align: center;
  margin: 3rem 0 4rem;
}
section.skills {
  margin-bottom: 3em;
}
section.skills .skills-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(6em, 1fr));
  gap: 2em;
  margin-top: 2rem;
}
section.skills .skills-card {
  background: #F9F1F1;
  padding: 1em;
  display: grid;
  place-items: center;
  position: relative;
  border-radius: 0.2em 0.2em 0 0;
}
section.skills .skills-card img {
  width: 3em;
  transition: all 200ms ease-in-out;
}
section.skills .skills-card::after {
  content: attr(data-skill);
  position: absolute;
  top: 100%;
  width: 100%;
  text-align: center;
  background: #F9F1F1;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2) inset;
  border-radius: 0 0 0.2em 0.2em;
  transform-style: preserve-3d;
  transform-origin: 50% 0;
  transform: perspective(1000px) rotateX(-90deg);
  transition: transform 200ms ease-out;
}
@media screen and (max-width: 750px) {
  section.skills .skills-card::after {
    transform: perspective(1000px) rotateX(0deg);
  }
}
section.skills .skills-card:hover img {
  transform: rotate(-10deg);
}
section.skills .skills-card:hover::after {
  transform: perspective(1000px) rotateX(0deg);
}
#contact {
  margin: 8rem 0 4rem;
  padding: 2rem;
}
#contact > * {
  margin: 0 auto;
  max-width: 120rem;
}
#contact h2 {
  margin-bottom: 4rem;
}
#contact .contact-flexbox {
  display: flex;
  justify-content: space-between;
  height: auto;
  border: 1px solid #850D4A;
  border-radius: 0.5em 0 0 0.5em;
  margin: 2rem auto;
  position: relative;
  overflow: hidden;
}
@media screen and (max-width: 750px) {
  #contact .contact-flexbox {
    border-radius: 0.5em;
  }
}
#contact .contact-flexbox::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 61%;
  background: #c285a4;
  z-index: 2;
  clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 7% 100%);
}
@media screen and (max-width: 750px) {
  #contact .contact-flexbox::before {
    display: none;
  }
}
#contact .contact-flexbox::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 60%;
  background: #850D4A;
  z-index: 3;
  clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 7% 100%);
}
@media screen and (max-width: 750px) {
  #contact .contact-flexbox::after {
    clip-path: none;
    width: 100%;
  }
}
#contact canvas.contact-animation {
  position: relative;
  z-index: 1;
  pointer-events: auto;
}
@media screen and (max-width: 750px) {
  #contact canvas.contact-animation {
    display: none;
  }
}
#contact form.contact-form {
  width: 50%;
  display: flex;
  flex-flow: column nowrap;
  padding: 4rem;
  z-index: 4;
}
@media screen and (max-width: 750px) {
  #contact form.contact-form {
    width: 100%;
  }
}
#contact form.contact-form input[type=text],
#contact form.contact-form input[type=email],
#contact form.contact-form textarea {
  background: rgba(255, 255, 255, 0.2);
  border: none;
  font-family: inherit;
  font-size: 0.7em;
  padding: 1em;
  margin-bottom: 1em;
  color: #F9F1F1;
  transition: outline 200ms ease-in-out;
}
#contact form.contact-form input[type=text]:placeholder,
#contact form.contact-form input[type=email]:placeholder,
#contact form.contact-form textarea:placeholder {
  color: rgba(255, 255, 255, 0.9);
}
#contact form.contact-form input[type=text]:focus,
#contact form.contact-form input[type=email]:focus,
#contact form.contact-form textarea:focus {
  outline: 2px solid rgba(255, 255, 255, 0.5);
}
#contact form.contact-form .contact-button {
  align-self: flex-end;
  padding: 1em;
  background: #850D4A;
  border: 2px solid rgba(255, 255, 255, 0.2);
  color: #F9F1F1;
  font-size: 0.7em;
  font-family: inherit;
  transform: translateX(-0.7em) skewX(-25deg);
  overflow: hidden;
  position: relative;
  transition: all 200ms ease-out 0.2s;
  cursor: pointer;
}
#contact form.contact-form .contact-button span {
  display: inline-block;
  transform: skewX(25deg);
}
#contact form.contact-form .contact-button::before {
  content: "";
  width: 100%;
  height: 100%;
  background: #F9F1F1;
  position: absolute;
  top: 0;
  left: -100%;
  transition: left 0.8s cubic-bezier(0.86, 0, 0.07, 1);
  z-index: -1;
}
#contact form.contact-form .contact-button::after {
  content: "";
  width: 100%;
  height: 100%;
  background: rgba(249, 241, 241, 0.3);
  position: absolute;
  top: 0;
  left: -100%;
  transition: left 0.6s cubic-bezier(0.86, 0, 0.07, 1) 0.2s;
  z-index: -2;
}
#contact form.contact-form .contact-button:hover {
  color: #850D4A;
}
#contact form.contact-form .contact-button:hover::before {
  left: 0%;
  transition: left 0.6s cubic-bezier(0.86, 0, 0.07, 1) 0.2s;
}
#contact form.contact-form .contact-button:hover::after {
  left: 0%;
  transition: left 0.8s cubic-bezier(0.86, 0, 0.07, 1);
}
#contact form.contact-form .button-success {
  background: #007C89;
}
#contact form.contact-form .button-fail {
  background: #DEC049;
}
#footer {
  background: #121212;
  color: #F9F1F1;
  padding: 2rem;
}
#footer > * {
  margin: 0 auto;
  max-width: 120rem;
}
.footer-links {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(6em, 1fr));
  align-items: center;
  gap: 2em;
  padding: 8rem 0;
}
.footer-links a {
  place-self: center;
}
.footer-links svg {
  width: 4em;
  transition: all 200ms ease-out;
  cursor: pointer;
}
.footer-links svg:not(.stroke-svg) {
  fill: #F9F1F1;
}
.footer-links svg:not(.stroke-svg):hover {
  fill: #DEC049;
}
.footer-links svg.stroke-svg {
  stroke: #F9F1F1;
}
.footer-links svg.stroke-svg:hover {
  stroke: #DEC049;
}
#copyright {
  background: #212121;
  color: #F9F1F1;
  padding: 1em;
  font-size: 0.8em;
  display: flex;
  justify-content: flex-end;
}
header {
  width: 100%;
  position: absolute;
  top: 0;
  padding: 2rem;
  z-index: 100;
}
nav {
  display: flex;
  justify-content: flex-end;
  max-width: 120rem;
  margin: 0 auto;
}
header a {
  text-decoration: none;
  border: none;
  color: #74B5AB;
  font-size: 2rem;
  padding: 1rem 2rem;
  transition: background-color 200ms ease-in;
}
header a:hover {
  background: #1A1A1A;
}
header a:not(:last-child) {
  margin-right: 1rem;
}
#hero {
  background: #121212;
  width: 100vw;
  height: 110vh;
  clip-path: polygon(0% 0%, 0% 95%, 50% 100%, 100% 95%, 100% 0%);
}
#canvas-hero {
  margin: 0;
  padding: 0;
}
@media screen and (max-width: 1280px) {
  #canvas-hero {
    display: none;
  }
}
#mobile-hero {
  height: 100vh;
  display: grid;
  place-items: center;
  text-align: center;
  padding: 1em;
}
#mobile-hero p {
  font-size: clamp(5rem, 2.653rem + 6.52vw, 11rem);
  font-weight: 800;
  color: white;
  line-height: 1.1;
}
#mobile-hero p span {
  color: #850D4A;
}
@media screen and (min-width: 1281px) {
  #mobile-hero {
    display: none;
  }
}
#projects {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  padding: 2rem;
  margin: 4rem 0;
}
#projects > * {
  width: 100%;
  max-width: 120rem;
}
#projects h2 {
  margin-bottom: 4rem;
}
.view-code-link {
  font-weight: bold;
  text-decoration: none;
  color: currentColor;
}
.view-code-link .view-code-text {
  opacity: 0;
  transition: opacity 200ms ease-out;
  /* No delay in normal state */
}
.view-code-link:hover .view-code-text {
  opacity: 1;
  transition: opacity 200ms 200ms ease-out;
  /* 200ms delay on hover */
}
.view-code-link span:last-child {
  margin-left: -42px;
  transition: margin-left 200ms 200ms ease-out;
  /* 200ms delay in normal state */
}
.view-code-link:hover span:last-child {
  margin-left: 0;
  transition: margin-left 200ms ease-out;
  /* No delay on hover */
}
.project-controls [type=checkbox] {
  width: 0;
  height: 0;
  visibility: hidden;
}
.project-controls label {
  font-size: 0.7em;
  padding: 0.5em 1em;
  border-radius: 0.5em;
  user-select: none;
  cursor: pointer;
  display: inline-block;
  overflow: hidden;
  position: relative;
  transition: color 200ms ease-out;
}
.project-controls label::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  transform: scale(0);
  transition: transform 200ms ease-in;
  z-index: -1;
}
.project-controls label[for=btn-all] {
  border: solid #121212;
}
.project-controls label[for=btn-all]::after {
  background: radial-gradient(circle at center, #121212 50%, white 51%);
}
.project-controls label[for=btn-js] {
  border: solid #ccb130;
}
.project-controls label[for=btn-js]::after {
  background: radial-gradient(circle at center, #ccb130 50%, white 51%);
}
.project-controls label[for=btn-firebase] {
  border: solid #f2c029;
}
.project-controls label[for=btn-firebase]::after {
  background: radial-gradient(circle at center, #f2c029 50%, white 51%);
}
.project-controls label[for=btn-node] {
  border: solid #7cc327;
}
.project-controls label[for=btn-node]::after {
  background: radial-gradient(circle at center, #7cc327 50%, white 51%);
}
.project-controls label[for=btn-react] {
  border: solid #46caf2;
}
.project-controls label[for=btn-react]::after {
  background: radial-gradient(circle at center, #46caf2 50%, white 51%);
}
.project-controls label[for=btn-css] {
  border: solid #3392ca;
}
.project-controls label[for=btn-css]::after {
  background: radial-gradient(circle at center, #3392ca 50%, white 51%);
}
.project-controls label[for=btn-scss] {
  border: solid #c36190;
}
.project-controls label[for=btn-scss]::after {
  background: radial-gradient(circle at center, #c36190 50%, white 51%);
}
.project-controls label[for=btn-html] {
  border: solid #d94924;
}
.project-controls label[for=btn-html]::after {
  background: radial-gradient(circle at center, #d94924 50%, white 51%);
}
.project-controls [type=checkbox]:checked + label {
  color: white;
}
.project-controls [type=checkbox]:checked + label::after {
  transform: scale(2);
}
.project-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  grid-gap: 1em;
  margin: 2em auto;
}
.project-card {
  box-shadow: 0 0 0.3em 0.05em rgba(0, 0, 0, 0.1);
  border-radius: 0.2em;
  overflow: hidden;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
}
.project-card h3, .project-card p, .project-card footer {
  width: 100%;
  font-size: 0.7em;
  padding: 0.5em 1em;
}
.project-card figure {
  width: 100%;
  overflow: hidden;
  position: relative;
}
.project-card figcaption {
  background: rgba(0, 0, 0, 0.9);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
  transition: all 200ms ease-out;
}
.project-card figcaption svg {
  height: 75%;
  fill: rgba(255, 255, 255, 0.2);
}
.project-card img {
  width: 100%;
  transform: scale(1.2);
  transition: transform 200ms ease-out;
}
.project-card:hover img {
  transform: scale(1);
}
.project-card:hover figcaption {
  background: rgba(0, 0, 0, 0.3);
  top: 99%;
}
.project-card footer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.showcase {
  display: flex;
  align-items: center;
}
.showcase a:first-child {
  text-decoration: none;
  color: #DEC049;
  margin-right: 0.5em;
  transition: all 200ms ease-out;
}
.showcase a:first-child:hover {
  color: #EBCB4D;
  position: relative;
  bottom: 2px;
}
.showcase a:last-child {
  line-height: 0;
  transition: all 200ms ease-out;
}
.showcase svg {
  width: 1.5em;
  fill: #850D4A;
  transition: all 200ms ease-out;
}
.showcase a:hover svg {
  fill: #D11576;
}
.languages {
  display: flex;
}
.languages div {
  border-radius: 50%;
  height: 1em;
  width: 1em;
  outline: solid white;
}
.languages div:not(:last-child) {
  margin-right: -0.25em;
}
.languages div[class$=html] {
  background: #d94924;
}
.languages div[class$=css] {
  background: #3392ca;
}
.languages div[class$=scss] {
  background: #c36190;
}
.languages div[class$=js] {
  background: #ccb130;
}
.languages div[class$=react] {
  background: #46caf2;
}
.languages div[class$=node] {
  background: #7cc327;
}
.languages div[class$=firebase] {
  background: #f2c029;
}
/*# sourceMappingURL=index.9bb8ae94.css.map */

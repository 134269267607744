#hero {
    background: $COLOR_BLACK;
    width: 100vw;
    height: 110vh;
    clip-path: polygon(0% 0%, 0% 95%, 50% 100%, 100% 95%, 100% 0%);
}

#canvas-hero {
    margin: 0;
    padding: 0;

    @media screen and (max-width: 1280px) {
        display: none;
    }
}

#mobile-hero {
    height: 100vh;
    display: grid;
    place-items: center;
    text-align: center;
    padding: 1em;

    p {
        font-size: clamp(5rem, 2.653rem + 6.52vw, 11rem);
        font-weight: 800;
        color: white;
        line-height: 1.1;

        span {
            color: $COLOR_PRIMARY;
        }
    }

    @media screen and (min-width: 1281px) {
        display: none;
    }
}